import React from "react"
import { Link } from "gatsby"

import styles from "./navDropdown.module.scss"

const NavDropdownItems = ({ items, clickHandler }) => {
  return (
    <ul className={styles.dropdownList}>
      {items.map(item => {
        return (
          <li key={`nav_item_${item._key}`} className={styles.dropdownItem}>
            <Link to={item.url} onClick={clickHandler}>
              <span>{item.title}</span>
              {item.description && <em>{item.description}</em>}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default NavDropdownItems
