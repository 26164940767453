import React from "react"
import YouTube from "react-youtube-embed"

const VideoEmbed = (props) => {
  const getYouTubeId = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
    const match = url.match(regExp)
    return (match && match[7].length === 11) ? match[7] : false
  }

  return (
    <YouTube id={getYouTubeId(props.url)} />
  )
}

export default VideoEmbed
