import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
// import algoliasearch from "algoliasearch/lite"
// import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom"
// import Search from "./search"
import styles from "./footer.module.scss"
import Logo from "../../assets/images/logo.png"
import LTG from "../../assets/svg/ltg-logo.svg"
import SocialIcon from "../components/socialIcon"
import { linkTest } from "../helpers/links"

// const searchClient = algoliasearch("XHD70HWICE", "7dcc3c073e9ce0f32fa3c4b7afdd08d2")

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        sanitySiteConfig {
          policyPagesStructure {
            structure {
              title
              _key
              url
            }
          }
          account {
            _key
            url
            platform
          }
        }
        sanityNav {
          products {
            _key
            description
            title
            url
          }
          solutions {
            _key
            description
            title
            url
          }
          discover {
            _key
            description
            title
            url
          }
          company {
            _key
            description
            title
            url
          }
        }
      }
    `}
    render={data => (
      <footer className={styles.footer}>
        <div className="grid">
          <div className="gridItem large-4">
            <div className={styles.footerLogo}>
              <img src={Logo} alt="logo" />
            </div>
            {/* To do: Implement search functionality */}
            {/*
              <InstantSearch searchClient={searchClient} indexName="PeopleFluent">
                <SearchBox />
                <Hits />
              </InstantSearch>
              <Search />
            */}
          </div>
          {Object.entries(data.sanityNav).map(([mainItem, items]) => (
            <div className="gridItem small-6 medium-3 large-2" key={mainItem}>
              <ul className={styles.footerList}>
                <li className={styles.footerListTitle}>{mainItem}</li>
                {items.map(item => (
                  <li key={item._key}>
                    <Link to={item.url}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <div className="gridItem">
            <div className={styles.footerCopyright}>
              {data.sanitySiteConfig.account && (
                <ul className={styles.footerSocialList}>
                  {data.sanitySiteConfig.account.map((acc, i) => (
                    <li key={acc._key + i}>
                      <a
                        href={acc.url}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        <span>Link to {acc.platform}</span>
                        <SocialIcon type={acc.platform} />
                      </a>
                    </li>
                  ))}
                </ul>
              )}
              <div>
                {data.sanitySiteConfig.policyPagesStructure && (
                  <div className="gridItem">
                    <ul className={styles.footerInlineList}>
                      {data.sanitySiteConfig.policyPagesStructure.structure.map(
                        (item, i) => (
                          <li key={item.url + i}>
                            {linkTest(item.url) === "external" ? (
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.title}
                              </a>
                            ) : (
                              <Link to={item.url}>{item.title}</Link>
                            )}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
                <a
                  href="https://www.ltgplc.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Part of Learning Technologies Group plc
                  <LTG />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )}
  />
)

export default Footer
