import React from "react"
import Figure from "./figure"

import styles from "./imageGrid.module.scss"

const ImageGrid = (props) => {
  return (
    <div className={styles.imageGrid}>
      {props.sectionTitle && <h2>{props.sectionTitle}</h2>}
      {props.gridRow.map(row => {
        const cols = 'images-' + row.cols
        return (
          <div className={styles.images} data-cols={row.cols}>
            {row.images.map(image => (
              <Figure key={image.id} {...image} />
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default ImageGrid
