import React from "react"

import ModalWrapper from "./modalWrapper"
import Form from "../modules/form"

const IframeModal = props => (
  <ModalWrapper {...props} isFullHeight={true}>
    <Form
      title={props.formTitle}
      link={props.formLink}
      subtitle={props.formSubtitle}
    />
  </ModalWrapper>
)

export default IframeModal
