const email = /\S+@\S+\.\S+/
const external = new RegExp("^(?:[a-z]+:)?//", "i")

export const linkTest = link => {
  if (typeof link !== "string") return
  if (email.test(link)) return "email"
  if (link.startsWith("tel:")) return "tel"
  if (!external.test(link)) return "internal"

  return "external"
}

export const linkFormat = link => {
  const linkType = linkTest(link)

  switch (linkType) {
    case "email":
      const email = link.replace(/^mailto:([^?]+).*/, "$1")
      return `mailto:${email}`

    case "internal":
      let constructedLink = link.charAt(0) !== "/" ? `/${link}` : link
      constructedLink = link.charAt(link.length - 1) === "/" ? constructedLink : `${constructedLink}/`

      return constructedLink

    default:
      return link
  }
}
