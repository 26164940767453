import React from "react"
import cx from "classnames"

import styles from "./multiQuote.module.scss"

const MultiQuote = ({ text1, author, text2, noBottomIndent }) => {
  const multiQuoteClass = cx(styles.multiQuote, {
    [styles.multiQuote__noBottomIndent]: !!noBottomIndent,
  })

  return (
    <div className={multiQuoteClass}>
      <span className={styles.quote}>{text1}</span>
      {author && <span className={styles.author}>&nbsp;{author}&nbsp;</span>}
      {text2 && <span className={styles.quote}>{text2}</span>}
    </div>
  )
}

export default MultiQuote
