import React from "react"
import ModalWrapper from "./modalWrapper"
import VideoEmbed from "../components/videoEmbed"

const VideoModal = (props) => {
  const getYouTubeId = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
    const match = url.match(regExp)
    return (match && match[7].length === 11) ? match[7] : false
  }

  return (
    <ModalWrapper {...props}>
      <VideoEmbed url={props.url} />
    </ModalWrapper>
  )
}

export default VideoModal
