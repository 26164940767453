import React, { useEffect } from "react"
import styles from "./modal.module.scss"

import { connect } from "react-redux"
import { closeModal } from "../redux/actions/modalActions"

const ModalWrapper = (props, {dispatch}) => {
  const onCloseModal = (evt) => {
    if (evt) { evt.preventDefault() }
    props.dispatch(closeModal({isOpen: !isOpen}))
  }

  useEffect(() => {
    // todo: refactor modals
    if (props.isOpen) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.documentElement.style.overflow = "visible";
    }

    return () => {
      document.body.style.overflow = "visible";
      document.documentElement.style.overflow = "visible";
    }
  }, [props.isOpen])

  const isOpen = props.isOpen
  return (
    <aside className={`${styles.modal} ${isOpen ? styles.isOpen : ''}  ${props.isFullHeight ? styles.isFullHeight : ''}`} tabIndex="-1" onClick={(evt) => onCloseModal(evt)}>
      <button className={styles.modalClose} aria-label="Close Modal" aria-labelledby="close-modal">
        <span id="close-modal">Close</span>
      </button>
      <div className={styles.modalInner} id="modal-content" onClick={(evt) => evt.stopPropagation()}>
        {props.children}
      </div>
    </aside>
  )
}

export default connect((state) => ({
  isOpen: state.modal.showModal
}), null)(ModalWrapper)
