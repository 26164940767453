import React from "react"
import { imageUrlFor } from "../lib/image-url"

const Figure = props => {
  const fit = props.fit ? props.fit : "crop"
  const src = imageUrlFor(props.image)
    .fit(fit)
    .url()

  if (!src) return null

  return (
    <figure>
      <img src={src} alt={props.alt} loading="lazy" />
      {props.caption && <figcaption>{props.caption}</figcaption>}
    </figure>
  )
}

export default Figure
