import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { connect } from "react-redux"
import { acceptCookies } from "../redux/actions/cookieActions"
import RichText from "./page-builder/richText"

import styles from "./cookieNotice.module.scss"

const CookieNotice = (props, { data }) => {
  const onAcceptCookiesClick = evt => {
    evt.preventDefault()
    props.acceptCookies()
  }

  return (
    <StaticQuery
      query={graphql`
        {
          sanitySiteConfig {
            _rawCookieMessage
          }
        }
      `}
      render={data => (
        <div className={styles.cookieNotice}>
          <RichText blocks={data.sanitySiteConfig._rawCookieMessage} />
          <button
            className={styles.cookieNoticeButton}
            onClick={onAcceptCookiesClick}
          >
            Accept Cookies
          </button>
        </div>
      )}
    />
  )
}

const mapDispatchToProps = dispatch => {
  return {
    acceptCookies: () => dispatch(acceptCookies()),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(CookieNotice)
