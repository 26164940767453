import React from "react"
import IframeResizer from "iframe-resizer-react"
import cx from "classnames"

import RichText from "../components/page-builder/richText"

import styles from "./form.module.scss"

const Form = props => {
  const formClass = cx({
    [styles.form]: !props.split,
    [styles.formSplit]: props.split,
  })

  return (
    <div className={formClass} id="peoplefluent-form">
      <div className={styles.formInner}>
        {props.title && <h1 className={styles.formTitle}>{props.title}</h1>}
        {props.subtitle && (
          <div 
            className={styles.formSubtitle} 
            style={{ textAlign: "center" }}
          >
            <RichText blocks={props.subtitle} />
          </div>
        )}
        <IframeResizer
          src={props.link}
          style={{ minHeight: "780px" }}
          width="100%"
          id="pardot-form"
          title={props.title}
          checkOrigin={false}
        />
      </div>
    </div>
  )
}

export default Form
