import React from "react"

import styles from "./checkList.module.scss"

const CheckList = (props) => {
  return (
    <ul className={styles.checkList} style={{ marginLeft: '35px', marginRight: '35px', paddingBottom: '0' }}>
      {props.listItem && props.listItem.map((item, i) =>
        <li key={`${props._key}_${i}`} className={styles.checkListItem}>{item}</li>
      )}
    </ul>
  )
}

export default CheckList
