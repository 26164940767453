import React from "react"

import styles from "./iframeEmbed.module.scss"

const IframeEmbed = (props) => {
  const { url, aspectRatio, height } = props
  const aspectObject = aspectRatio !== undefined ? aspectRatio.split(':') : null
  const padding = aspectObject !== null ? `calc(${aspectObject[1]} / ${aspectObject[0]} * 100%)` : `calc(9 / 16 * 100%)`
  return (
    <>
      {!height ? (
        <div className={styles.iframeEmbed} style={{paddingTop: padding}}>
          <iframe allowFullScreen width="100%" height="160" src={url} frameBorder="0" crossOrigin="anonymous"></iframe>
        </div>
      ) : (
        <iframe allowFullScreen width="100%" height={height} src={url} frameBorder="0" crossOrigin="anonymous"></iframe>
      )}
    </>
  )
}

export default IframeEmbed
