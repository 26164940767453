import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { checkCookies } from "../redux/actions/cookieActions"

import "../_global/base.scss"
import "../_global/grid.scss"
import "./layout.module.scss"

import Header from "./header"
import Footer from "./footer"
import ModalConductor from "../modals/modalConductor"
import CookieNotice from "../components/cookieNotice"

class Layout extends Component {
  componentDidMount() {
    this.props.checkCookies()
  }

  render(props) {
    return (
      <>
        <Helmet></Helmet>
        {!this.props.disableHeader ? (
          <Header shouldTrans={this.props.transition} />
        ) : null}
        <main>{this.props.children}</main>
        <Footer />
        {!this.props.cookiesAccepted_pf && <CookieNotice />}
        <ModalConductor />
      </>
    )
  }
}

const mapStateToProps = state => ({
  cookiesAccepted_pf: state.cookie.cookiesAccepted_pf,
})

const mapDispatchToProps = dispatch => {
  return {
    checkCookies: () => dispatch(checkCookies()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
