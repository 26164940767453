import React, { Component } from "react"
import { Link } from "gatsby"

import Nav from "./nav"
import Logo from "../../assets/svg/logo-color.svg"
import styles from "./header.module.scss"

class Header extends Component {
  render() {
    return (
      <header
        className={styles.header}
        data-should-trans={this.props.shouldTrans}
      >
        <div className={styles.headerInner}>
          <Link to="/">
            <span className={styles.headerLogoTitle}>
              PeopleFluent logo linking to homepage
            </span>
            <Logo className={styles.headerLogo} />
          </Link>
          <Nav />
        </div>
      </header>
    )
  }
}

export default Header
