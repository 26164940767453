import imageUrlBuilder from '@sanity/image-url'

const config = {
  "projectId": `${process.env.GATSBY_PROJECT_ID}`,
  "dataset": `${process.env.GATSBY_DATASET}`
}
const builder = imageUrlBuilder(config)

export function imageUrlFor (source) {
  return builder.image(source)
}
