import React, { Component } from "react"
import { connect } from "react-redux"
import {
  checkCookies,
  acceptCookies,
  removeCookies,
} from "../redux/actions/cookieActions"

import styles from "./cookieNotice.module.scss"

class CookieButton extends Component {
  componentDidMount() {
    this.props.checkCookies()
  }

  onAcceptCookiesClick = evt => {
    evt.preventDefault()
    this.props.acceptCookies()
  }

  onRemoveCookiesClick = evt => {
    evt.preventDefault()
    this.props.removeCookies()
  }

  render() {
    return (
      <>
        {this.props.cookiesAccepted_pf ? (
          <button
            className={styles.cookieNoticeButton}
            onClick={this.onRemoveCookiesClick}
          >
            Remove Cookies
          </button>
        ) : (
          <button
            className={styles.cookieNoticeButton}
            onClick={this.onAcceptCookiesClick}
          >
            Accept Cookies
          </button>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  cookiesAccepted_pf: state.cookie.cookiesAccepted_pf,
})

const mapDispatchToProps = dispatch => {
  return {
    checkCookies: () => dispatch(checkCookies()),
    acceptCookies: () => dispatch(acceptCookies()),
    removeCookies: () => dispatch(removeCookies()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookieButton)
