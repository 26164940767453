import React from "react"
import BaseBlockContent from "@sanity/block-content-to-react"

import Figure from "../figure"
import ImageGrid from "../imageGrid"
import VideoEmbed from "../videoEmbed"
import MultiQuote from "../multiQuote"
import IframeEmbed from "../iframeEmbed"
import Table from "../table"
import CheckList from "./checkList"
import CookieButton from "../cookieButton"
import { linkTest } from "../../helpers/links"
import Button from "../button"

import styles from "./richText.module.scss"

const serializers = {
  types: {
    block: props => {
      switch (props.node.style) {
        case "address":
          return <address>{props.children}</address>
        case "blockquote":
          return <MultiQuote text1={props.children[0]} />
        default:
          if (props.children.indexOf("[remove-cookie-acceptance]") !== -1) {
            return <CookieButton />
          }
          return BaseBlockContent.defaultSerializers.types.block(props)
      }
    },
    figure(props) {
      return <Figure {...props.node} />
    },
    imageGrid(props) {
      return <ImageGrid {...props.node} />
    },
    videoEmbed(props) {
      return <VideoEmbed {...props.node} />
    },
    iframeEmbed(props) {
      return <IframeEmbed {...props.node} />
    },
    checkList(props) {
      return <CheckList {...props.node} />
    },
    multiColTable(props) {
      return <Table {...props.node} />
    },
    multiQuote(props) {
      return <MultiQuote {...props.node} />
    },
  },
  marks: {
    link: ({ children, mark }) => {
      const type = mark.button ? "secondary" : "ghostSecondary"

      switch (mark.linkType) {
        case "Internal":
          const internalLink = linkTest(mark.link) === "internal"
          if (internalLink) {
            return (
              <Button
                type={type}
                to={mark.link}
                label={children}
                target={mark.newTab}
                noFollow={mark.noFollow}
              />
            )
          } else {
            return (
              <Button
                type={type}
                to={mark.link}
                label={children}
                target={mark.newTab}
                noFollow={mark.noFollow}
              />
            )
          }

        case "Email":
          const email = mark.link.replace(/^mailto:([^?]+).*/, "$1")
          return <Button type={type} to={`mailto:${email}`} label={children} />

        case "Telephone":
          const tel = mark.link.replace(/^tel:([^?]+).*/, "$1")
          return <Button type={type} to={`tel:${tel}`} label={children} />

        default:
          const defaultLink = mark.link ? mark.link : mark.href
          const internal = linkTest(defaultLink) === "internal"
          return internal ? (
            <Button
              type={type}
              to={defaultLink}
              label={children}
              target={mark.newTab}
              noFollow={mark.noFollow}
            />
          ) : (
            <Button
              type={type}
              to={defaultLink}
              label={children}
              target={mark.newTab}
              noFollow={mark.noFollow}
            />
          )
      }
    },
    sup: ({ children }) => {
      return <sup>{children}</sup>
    },
  },
}

const RichText = ({ blocks }) => (
  <div className={styles.richText}>
    <BaseBlockContent blocks={blocks} serializers={serializers} />
  </div>
)

export default RichText
