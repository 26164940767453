export const showVideoModal = (payload) => {
  return { type: "SHOW_VIDEO_MODAL", payload }
}

export const showProfileModal = (payload) => {
  return { type: "SHOW_PROFILE_MODAL", payload }
}

export const showPartnerModal = (payload) => {
  return { type: "SHOW_PARTNER_MODAL", payload }
}

export const showIframeModal = (payload) => {
  return { type: "SHOW_IFRAME_MODAL", payload }
}

export const closeModal = (payload) => {
  return { type: "CLOSE_MODAL", payload }
}
