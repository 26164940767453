import React from "react"
import { connect } from "react-redux"

import Video from "./video"
import Profile from "./profile"
import Partner from "./partner"
import Iframe from "./iframe"

const ModalConductor = (props) => {
  switch (props.currentModal) {
    case "video":
      return <Video {...props} isOpen={props.isOpen} url={props.url} />

    case "profile":
      return <Profile {...props} isOpen={props.isOpen} />

    case "partner":
      return <Partner {...props} isOpen={props.isOpen} />

    case "iframe":
      return (
        <Iframe
          isOpen={props.isOpen}
          formTitle={props.formTitle}
          formLink={props.formLink}
          formSubtitle={props.formSubtitle}
        />
      )

    default:
      return null
  }
}

const mapStateToProps = state => ({
  isOpen: state.modal.showModal,
  currentModal: state.modal.modalType,
  url: state.modal.url ? state.modal.url : null,
  image: state.modal.image ? state.modal.image : null,
  name: state.modal.name ? state.modal.name : null,
  position: state.modal.position ? state.modal.position : null,
  bio: state.modal.bio ? state.modal.bio : null,
  formTitle: state?.modal?.formTitle,
  formLink: state?.modal?.formLink,
  formSubtitle: state?.modal?.formSubtitle,
})

export default connect(mapStateToProps)(ModalConductor)
