import React from "react"
import ModalWrapper from "./modalWrapper"
import Figure from "../components/figure"
import Button from "../components/button"
import RichText from "../components/page-builder/richText"

import styles from "./partner.module.scss"

const PartnerModal = (props) => {
  return (
    <ModalWrapper {...props}>
      <div className={styles.partner}>
        <div className={styles.partnerHeader}>
          <Figure image={props.image} />
          <h2>{props.name}</h2>
        </div>
        {props.bio && <div className={styles.partnerBody}>
          <RichText blocks={props.bio} />
        </div>}
        {props.url && <Button to={props.url} type="primary" target="true" noFollow="true" label="Go to website" />}
      </div>
    </ModalWrapper>
  )
}

export default PartnerModal
