import React from "react"
import ModalWrapper from "./modalWrapper"
import Figure from "../components/figure"
import RichText from "../components/page-builder/richText"

import styles from "./profile.module.scss"

const ProfileModal = props => {
  return (
    <ModalWrapper {...props}>
      <div className={styles.profile}>
        <div className={styles.profileHeader}>
          <Figure image={props.image} />
          <h2>{props.name}</h2>
          {props.position && <h4>{props.position}</h4>}
        </div>
        {props?.bio && (
          <div className={styles.profileBody}>
            <RichText blocks={props.bio} />
          </div>
        )}
      </div>
    </ModalWrapper>
  )
}

export default ProfileModal
