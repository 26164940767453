import React from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import cx from "classnames"

import { showVideoModal, showIframeModal } from "../redux/actions/modalActions"
import { linkFormat, linkTest } from "../helpers/links"

import styles from "./button.module.scss"

const Button = (props, { isOpen }) => {
  const internalLink = linkTest(props?.to) === "internal"
  const id = props.id ? { ["id"]: props.id } : null
  const clickCallback =
    props.action || props.isIframe
      ? {
          ["onClick"]: evt => {
            handleClick(evt)
          },
        }
      : null
  const clickHandler = props.clickHandler
    ? { ["onClick"]: props.clickHandler }
    : null
  const target = props.target
    ? { ["target"]: "_blank", ["rel"]: "noopener noreferrer" }
    : null
  const noFollow = props.noFollow
    ? { ["rel"]: "nofollow noopener noreferrer" }
    : null
  const modal = props.action || props.isIframe ? { ["data-modal"]: true } : null

  const to =
    (internalLink && !props.target) || internalLink
      ? linkFormat(props?.to)
      : props?.to
      ? props.to
      : ""

  let handleClick = evt => {
    evt.preventDefault()
    if (props.isIframe) {
      props.dispatch(
        showIframeModal({
          isOpen: !isOpen,
          url: props.formLink,
          formTitle: props.formTitle,
          formLink: props.formLink,
          formSubtitle: props.formSubtitle,
        })
      )
    } else {
      props.dispatch(showVideoModal({ isOpen: !isOpen, url: props.to }))
    }
  }

  const buttonClass = cx(styles.default, {
    [styles.fixed]: props.fixed,
    [styles.action]: props.action,
    [props.className]: props.className,
  })

  return (
    <>
      {internalLink && !props.target ? (
        <Link
          className={buttonClass}
          to={to}
          {...id}
          {...clickCallback}
          {...clickHandler}
          data-type={props.type}
          data-action={props.action}
          {...modal}
          {...target}
          {...noFollow}
        >
          {props.label}
        </Link>
      ) : (
        <a
          className={buttonClass}
          href={to}
          {...id}
          {...clickCallback}
          data-type={props.type}
          data-action={props.action}
          {...modal}
          {...target}
          {...noFollow}
        >
          {props.label}
        </a>
      )}
    </>
  )
}

export default connect(
  state => ({
    isOpen: state.modal.showModal,
    url: state.modal.url,
  }),
  null
)(Button)
