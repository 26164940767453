import React from "react"

import styles from "./table.module.scss"

const Table = ({headerRow, table, tableTitle}) => {
  const tHead = headerRow && table.rows ? table.rows.shift() : null
  const linkTest = (text) => {
    const words = text.split(' ')
    let newWords = []
    words.forEach(word => {
      const link = new RegExp('^(?:[a-z]+:)?//', 'i')
      link.test(word) ? newWords.push(`<a href='${word}>'>${word}</a>`): newWords.push(word)
    })
    return {__html: newWords.join(' ')}
  }
  return (
    <section className={styles.tableWrapper}>
      {tableTitle && <h3 className={styles.tableTitle}>{tableTitle}</h3>}
      <table className={styles.table}>
        {tHead !== undefined && tHead !== null &&
          <thead>
            <tr>
              {tHead.cells.map(cell =>
                <th>{cell}</th>
              )}
            </tr>
          </thead>
        }
        <tbody>
          {table.rows.length > 0 && table.rows.map(row =>
            <tr>
              {row.cells && row.cells.map(cell =>
                <td dangerouslySetInnerHTML={linkTest(cell)} />
              )}
            </tr>
          )}
        </tbody>
      </table>
    </section>
  )
}

export default Table
