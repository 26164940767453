import React from "react"

import Facebook from "../../assets/svg/social-facebook.svg"
import Instagram from "../../assets/svg/social-instagram.svg"
import LinkedIn from "../../assets/svg/social-linkedin.svg"
import X from "../../assets/svg/social-x.svg"
import YouTube from "../../assets/svg/social-youtube.svg"

const SocialIcon = props => {
  switch (props.type) {
    case "Facebook":
      return <Facebook />

    case "Instagram":
      return <Instagram />

    case "LinkedIn":
      return <LinkedIn />

    case "X":
      return <X />

    case "YouTube":
      return <YouTube />

    default:
      return null
  }
}

export default SocialIcon
