import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import { connect } from "react-redux"
import { toggleMobileNav } from "../redux/actions/navActions"
import Button from "../components/button"
import DropdownItems from "./navDropdownItems"

import styles from "./nav.module.scss"

class NavTemplate extends Component {
  handleClick = () => {
    this.props.dispatch(toggleMobileNav(!this.props.mobileNavOpen))
  }

  handleDropdownClick = ref => {
    if (window.innerWidth < 1024) {
      ref.getAttribute("data-dropdown-open")
        ? ref.removeAttribute("data-dropdown-open")
        : ref.setAttribute("data-dropdown-open", true)
    }
    return
  }

  handleLinkClick = () => {
    this.props.dispatch(toggleMobileNav(false))
  }

  render() {
    const nav = this.props.sanityNav

    return (
      <div className={styles.navSide}>
        <nav
          className={styles.nav}
          id="menu"
          hidden={!this.props.mobileNavOpen}
        >
          <ul className={styles.navList}>
            {Object.entries(nav).map(([mainItem, items]) => (
              <li
                className={`${styles.navItem} ${styles.hasDropdown}`}
                key={mainItem}
              >
                <p
                  ref={node => {
                    this[mainItem] = node
                  }}
                  onClick={() => this.handleDropdownClick(this[mainItem])}
                  style={{ fontFamily: "'Raleway', sans-serif" }} // Apply the Raleway font
                >
                  {mainItem}
                </p>
                <div className={styles.navDropdown}>
                  <DropdownItems
                    items={items}
                    clickHandler={this.handleLinkClick}
                  />
                </div>
              </li>
            ))}
          </ul>
        </nav>
        <Button
          className={styles.navButton}
          type="primary"
          to="/request-a-demo/"
          label="Try PeopleFluent"
          id="request-a-demo-header"
          clickHandler={this.handleLinkClick}
        />
        <button
          className={styles.navTrigger}
          aria-expanded={this.props.mobileNavOpen}
          aria-controls="menu"
          onClick={this.handleClick}
          style={{ cursor: "pointer" }}
        >
          <div className={styles.navTrigger__title}>Menu</div>
          <span></span>
        </button>
      </div>
    )
  }
}

export const Nav = ({ dispatch, mobileNavOpen }) => (
  <StaticQuery
    query={graphql`
      query {
        sanityNav {
          products {
            _key
            description
            title
            url
          }
          solutions {
            _key
            description
            title
            url
          }
          discover {
            _key
            description
            title
            url
          }
          company {
            _key
            description
            title
            url
          }
        }
      }
    `}
    render={data => (
      <NavTemplate
        {...data}
        dispatch={dispatch}
        mobileNavOpen={mobileNavOpen}
      />
    )}
  />
)

export default connect(
  state => ({
    mobileNavOpen: state.nav.showMobileNav,
  }),
  null
)(Nav)
